@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

/* Main Header
----------------------------------------------------------------------------------------*/
.content-main-header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.main-header {
  position: relative;
  height: 60px;
  background-color: #0e004b;
  color: #fff;
}

.fake-main-header {
  position: relative;
  height: 60px;
}

.main-header .website-logo {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 30px;
}

.main-header .website-title {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.7px;
  color: #fff;
  text-decoration: none;
}

.main-header .float-right > * {
  vertical-align: middle;
}

/* header-separator
----------------------------------------------------------------------------------------*/

.header-separator {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px;
  width: 1px;
  height: 29px;
  opacity: 0.25;
  background-color: #ffffff;
}

/* header-menu
----------------------------------------------------------------------------------------*/

.main-header .menu {
  position: relative;
  display: flex;
  vertical-align: middle;
  list-style-type: none;
  column-gap: 30px;
  align-items: center;
}

.main-header .menu li {
  display: inline-block;
  vertical-align: top;
}

.main-header .menu .helpMenu {
  background-image: url("../../res/images/help-menu/help-icon.png");
  background-size: 24px 24px;
  width: 32px;
  height: 55px;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: #fff;
}

.main-header .menu .helpMenu.active {
  background-image: url("../../res/images/help-menu/help-icon-active.png");
  background-size: 32px 32px;
}

.main-header .menu > li > a {
  font-size: 15px;
  color: #fff;
  opacity: 0.6;
  text-decoration: none;
}

.main-header .menu > li > a:hover,
.main-header .menu > li.active > a {
  opacity: 1;
}

.header-menu .displayed {
  position: relative;
  cursor: default;
  height: 60px;
}

.header-menu .submenu {
  position: absolute;
  right: 0;
  max-width: 320px;
  margin: 7px 0 0;
  padding: 16px 0 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  visibility: hidden;
  box-sizing: border-box;
  opacity: 0;
  margin-top: 0;
}

.header-menu.profile-menu .submenu {
  width: 320px;
  max-width: calc(100vw - 30px);
}

.header-menu .submenu ul {
  display: block;
  list-style-type: none;
}

.header-menu .submenu ul li {
  display: block;
  margin: 0;
  padding: 0;
}

.header-menu .submenu ul li:not(.separator) {
  background-color: #fff;
}

.header-menu .submenu ul li:not(.separator):hover {
  background-color: #fafafa;
}

.header-menu .submenu ul li.separator {
  height: 1px;
  margin: 4px 0;
  margin-left: 16px;
  padding: 0;
  background-color: #ebebeb;
}

.header-menu .submenu ul li.separator.full {
  margin-left: 0;
}

.header-menu .submenu ul li a {
  padding: 0 16px;
  line-height: 40px;
  color: #000;
  font-size: 16px;
  opacity: 1;
}
.header-menu .submenu ul li.link-account {
  position: relative;
}
.header-menu .submenu ul li.link-account:not(.multi-account):hover {
  background-color: #fff;
}
.header-menu
  .submenu
  ul
  li.link-account.multi-account
  > div.content-switch-account {
  position: relative;
}
.header-menu
  .submenu
  ul
  li.link-account.multi-account
  > div.content-switch-account
  .icon {
  position: absolute;
  width: 8px;
  height: 12px;
  top: 50%;
  margin-top: -6px;
  right: 16px;
  background-image: url("../../res/images/link-account-arrow.svg");
  background-size: 8px 12px;
  background-repeat: no-repeat;
  background-position: center center;
}
.header-menu
  .submenu
  ul
  li.link-account.multi-account.opened
  > div.content-switch-account
  .icon {
  transform: rotate(90deg);
}
.header-menu .submenu ul li.link-account > div {
  padding-top: 13px;
  padding-bottom: 13px;
  line-height: 1.2;
  position: relative;
}
.header-menu .submenu ul li.link-account .content-account-switcher {
  position: absolute;
  box-sizing: border-box;
  right: 100%;
  padding-right: 12px;
  padding-top: 0;
  top: 0;
}
.header-menu .submenu ul li.link-account .account-switcher {
  position: relative;
  width: 200px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding: 5px 0;
  opacity: 0;
  visibility: hidden;
}
.header-menu .submenu ul li.link-account:hover .account-switcher {
  opacity: 1;
  visibility: visible;
}
.header-menu .submenu ul li.link-account .account-switcher .title {
  line-height: 40px;
  padding: 0 16px;
}

.header-menu .submenu ul li.link-account .account-switcher .separator {
  margin: 5px 0;
  height: 1px;
  background: #ebebeb;
}
.header-menu
  .submenu
  ul
  li.link-account
  .account-switcher
  .content-accounts
  .account {
  line-height: 40px;
  padding: 0 16px;
  background-color: #fff;
}
.header-menu
  .submenu
  ul
  li.link-account
  .account-switcher
  .content-accounts
  .account:hover {
  background-color: #fafafa;
}

.header-menu .content-user .picture {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 54px;
  height: 54px;
  line-height: 54px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 27px;
  margin-right: 12px;
  background-image: url("../../res/images/avatar-default.svg");
  box-sizing: border-box;
  font-size: 24px;
}
.header-menu .content-user .infos {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  max-width: 200px;
}

.header-menu .submenu ul .menu-action {
  display: block;
  text-decoration: none;
}

.header-menu:hover .submenu {
  visibility: visible;
  opacity: 1;
}
.header-menu.profile-menu .setup-account a {
  text-decoration: none;
  display: block;
  padding: 0;
}
.header-menu.profile-menu .setup-account .content-progress-bar {
  position: relative;
  height: 4px;
  border-radius: 4px;
  background-color: #ebebeb;
  overflow: hidden;
}

.header-menu.profile-menu .setup-account .content-progress-bar .progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  height: 4px;
  background-image: linear-gradient(to right, #4bd69d 0%, #30beec 100%);
}

.header-menu.language-menu .displayed .lang,
.header-menu.profile-menu .displayed .username {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: default;
  margin-top: -4px;
}

.header-menu.language-menu .displayed .lang {
  width: 20px;
}

.header-menu.language-menu .displayed .arrow,
.header-menu.profile-menu .displayed .picture {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.header-menu.profile-menu .displayed .picture {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 15px;
  margin-right: 4px;
  background-image: url("../../res/images/avatar-default.svg");
  border: transparent solid #fff;
  box-sizing: border-box;
  border-width: 0;
}
.header-menu.profile-menu:hover .displayed .picture {
  border: 2px solid #fff;
  line-height: 26px;
}

.header-menu.profile-menu .displayed .mini-arrow {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 5px 0 5px;
  border-color: #ffffff transparent transparent transparent;
}

.header-menu.language-menu .displayed .arrow {
  width: 8px;
  height: 4px;
  background-size: 8px auto;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: 5px;
  margin-top: -3px;
}

@media all and (max-width: 560px) {
  .header-menu
    .submenu
    ul
    li.link-account.multi-account.opened
    .content-account-switcher {
    display: block;
  }
  .header-menu .submenu ul li.link-account .content-account-switcher {
    display: none;
    position: relative;
    right: 0;
    padding-right: 0;
    top: 0;
    padding-bottom: 0;
  }
  .header-menu .submenu ul li.link-account .account-switcher {
    width: auto;
    visibility: visible;
    opacity: 1;
    box-shadow: none;
  }
}

@media all and (max-width: 715px) {
  .main-header .menu li.menu-li-link {
    display: none;
  }
}

/* .header-unconnected
----------------------------------------------------------------------------------------*/

.content-header-unconnected.absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
}

.header-unconnected {
  position: absolute;
  display: block;
  z-index: 2;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 123px;
  height: 28px;
}
.header-unconnected:hover {
  opacity: 0.8;
}

/* .orange-header
----------------------------------------------------------------------------------------*/

.orange-header {
  position: relative;
  z-index: 10;
  padding: 8px 40px;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  background-color: #f78b7c;
}
.orange-header.fake {
  position: relative;
  opacity: 0;
  visibility: hidden;
}

.icon.plan-frame {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  background-image: url("../../res/images/plan-icons/frame.svg");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.icon.plan-lock-key {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-image: url("../../res/images/plan-icons/lock-key.svg");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.icon.plan-lock-key-orange {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background-image: url("../../res/images/plan-icons/lock-key-orange.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

/* End of file
----------------------------------------------------------------------------------------*/
