.prose-chat :where(table) {
  margin-top: 15px;
  margin-bottom: 15px;
  border-collapse: collapse;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  overflow-x: auto;
  max-width: 100%;
  border: 1px solid #343434;
}

.prose-chat :where(td) {
  border: 1px solid #343434;
  padding: 10px;
  text-align: left;
}

.prose-chat :where(th) {
  background-color: #ededed;
  border: 1px solid #343434;
  padding: 10px;
  text-align: left;
}

.prose-chat :where(td img) {
  width: 100px;
}
